<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加审核人</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['DocumentAuditorDto'] }">
              <button-delete :method="table.operation.delete.click" :params="row"> 删除 </button-delete>
              <button-delete
                link
                v-if="row.user.ssoUser && row.ssoUser?.wechatUser"
                content="确定解绑吗？"
                :method="table.operation.unbind.click"
                :params="row"
              >
                微信解绑
              </button-delete>
              <button-ajax link v-else :method="table.operation.bind.click" :params="row">微信绑定</button-ajax>
            </template>
          </en-table-column>
          <en-table-column label="人员" prop="user">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['DocumentAuditorDto'] }">
              <en-button type="primary" text @click="table.name.click(row)">{{ row.user?.name }}</en-button>
            </template>
          </en-table-column>
          <en-table-column label="备注" prop="comment"> </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑服务顾问' : '添加服务顾问'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="人员名称" prop="user">
        <select-maintain
          v-model="detail.form.data.user"
          :ajax="{ action: 'GET /enocloud/common/user', params: (params, value) => (params.payload = { name: value }) }"
          :props="{
            label: 'name',
            value: '',
            disabled: (option) => {
              return Boolean(table.data.find((item) => item.user.id === option.id))
            }
          }"
          value-key="id"
          remote
          class="w-full"
          :disabled="detail.selectDisabled"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" maxlength="200" type="textarea" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { h } from 'vue'
import { EnMessageBox } from '@enocloud/components'
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        },
        edit: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/document/auditor',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { type: 'SERVICE_DISCOUNT_RATE' }
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/document/auditor/:documentAuditorId',
            loading: true
          },
          bind: {
            action: 'GET /enocloud/admin/user/:userId/wechat/bind/url'
          },
          unbind: {
            action: 'POST /enocloud/admin/user/:userId/wechat/unbind'
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['DocumentAuditorDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            bind: {
              async click(row: EnocloudCommonDefinitions['DocumentAuditorDto']) {
                const res = await this.table.bind({ paths: [row.id] })
                return EnMessageBox({
                  title: row.user?.name,
                  center: true,
                  showConfirmButton: false,
                  message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                })
              }
            },
            unbind: {
              async click(row: EnocloudCommonDefinitions['DocumentAuditorDto']) {
                await this.table.unbind({ paths: [row.id] })
                return this.table.get()
              }
            }
          },
          name: {
            click(row: EnocloudCommonDefinitions['DocumentAuditorDto']) {
              this.detail.form.init()
              this.detail.form.data = Object.assign({}, row)
              this.detail.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {} as EnocloudCommonDefinitions['DocumentAuditorDto'],
            ajax: {
              get: {
                action: 'GET /enocloud/common/document/auditor/:documentAuditorId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/document/auditor',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = Object.assign({}, this.detail.form.data, { type: { code: 'SERVICE_DISCOUNT_RATE' } })
                }
              },
              update: {
                action: 'PUT /enocloud/common/document/auditor',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: { user: [{ required: true, message: '请选择人员名称' }] }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
